import { ArrowBack } from "@mui/icons-material";
import { Button, Grid, IconButton, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import dayjs from "dayjs";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import colors from "../colors";
import { RootState } from "../redux/store";
import { delUser } from "../redux/user/slice";
import { cleanStorage, cleanToken } from "../storage";

const Account: FC = () => {
  const userState = useSelector((state: RootState) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleBack = () => {
    navigate(-1);
  };

  const handleLogout = () => {
    if (window.confirm("Sair da sua conta?")) {
      dispatch(delUser());
      cleanToken();
      cleanStorage();
    }
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: colors.primary,
          margin: 0.5,
          borderRadius: 1,
          height: "100vh",
        }}
      >
        <Grid container spacing={0}>
          <Grid item xs={1}>
            <IconButton onClick={handleBack}>
              <ArrowBack />
            </IconButton>
          </Grid>
          <Grid item xs={10}>
            <Typography
              sx={{ mt: 1, color: colors.main, fontWeight: "bold" }}
              component="h1"
            >
              Conta
            </Typography>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={12}>
            <form>
              <TextField sx={{ m: 1 }} disabled value={userState.user.name} />
              <TextField sx={{ m: 1 }} disabled value={userState.user.email} />
            </form>
          </Grid>
        </Grid>
        <Typography sx={{ bottom: 0, color: colors.main }} variant="caption">
          Conta criada em:{" "}
          {dayjs(userState.user?.createdAt).format("DD/MM/YYYY HH:mm:ss")}
        </Typography>
        <div style={{ position: "absolute", bottom: 0 }}>
          <Button
            variant="contained"
            sx={{ m: 1, backgroundColor: colors.main }}
            onClick={handleLogout}
          >
            Sair
          </Button>
        </div>
      </Box>

      <footer>
        <Typography variant="caption" align="right">
          Feito com ♥ por{" "}
          <a style={{ color: "#e5e5e5" }} href="https://github.com/jaopsb">
            @jaopsb
          </a>
        </Typography>
      </footer>
    </>
  );
};

export default Account;
