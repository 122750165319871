import {
  Button,
  CircularProgress,
  FormGroup,
  InputAdornment,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import colors from "../colors";
import { v4 as uuidv4 } from "uuid";
import { Box, SxProps } from "@mui/system";
import { DatePicker } from "@mui/x-date-pickers";
import { FC, FormEventHandler, useState } from "react";
import { useDispatch } from "react-redux";
import { addRecord } from "../redux/gas-records/slice";
import { handleAddRecord } from "../api";

type GasRecordModal = {
  open: boolean;
  onClose: () => void;
};

const style: SxProps = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: colors.contrast,
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

const typographyTitle: SxProps = {
  color: "#14213D",
  mb: 2,
};
const initialRecord = {
  id: "null",
  date: dayjs().format(),
  km: 0,
  lt: "",
  price: "",
};

const dotRegex = /^\d+(\.\d{0,2})?$/;
const commaRegex = /^\d+(,\d{0,2})?$/;

const CreateGasRecord: FC<GasRecordModal> = (props) => {
  const [loadingSubmit, setLoadingSubmit] = useState({
    msg: "",
    isError: false,
    value: false,
  });
  const { open, onClose } = props;

  const dispatch = useDispatch();

  const [newRecord, setNewRecord] = useState(initialRecord);

  const handleSubmit: FormEventHandler = (e) => {
    e.preventDefault();

    newRecord.id = uuidv4();

    const { date, km, lt, price } = newRecord;

    if (dayjs(date).isAfter(dayjs())) {
      return alert(
        "Desculpa mas abastecimentos no futuro ainda não são suportados!"
      );
    }
    let numLT = Number(lt.replace(",", "."));
    let numPRICE = Number(price.replace(",", "."));

    if (km <= 0 || numLT <= 0 || numPRICE <= 0) {
      return alert(
        "Verifique os campos inserido! Valores negativo ou zero não são permitidos!"
      );
    }

    setLoadingSubmit({
      msg: "",
      isError: false,
      value: true,
    });

    const aux = {
      ...newRecord,
      lt: numLT,
      price: numPRICE,
    };

    handleAddRecord(aux)
      .then((res) => {
        const { record } = res.data;

        if (record) {
          dispatch(addRecord(record));

          setNewRecord(initialRecord);

          setLoadingSubmit({
            isError: false,
            value: false,
            msg: "",
          });

          onClose();
        } else {
          const { msg } = res.data;
          if (msg) {
            setLoadingSubmit({
              isError: true,
              value: false,
              msg,
            });
          }
        }
      })
      .catch((res) => {
        setLoadingSubmit({
          isError: true,
          value: false,
          msg: res.response.data.msg,
        });
      });
  };
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <Typography sx={typographyTitle} align="center" variant="h5">
          Novo Abastecimento ⛽
        </Typography>
        <form onSubmit={handleSubmit} style={{ margin: "0 auto" }}>
          <FormGroup>
            <TextField
              sx={{ m: 1 }}
              value={newRecord.km}
              type="number"
              helperText="Valor de Km no Hodômetro"
              onChange={(value) =>
                setNewRecord({ ...newRecord, km: Number(value.target.value) })
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">Km</InputAdornment>
                ),
              }}
            />
            <FormGroup>
              <TextField
                sx={{ m: 1 }}
                value={newRecord.price}
                helperText="Quanto foi pago no Posto"
                onChange={(e) =>
                  dotRegex.test(e.target.value) ||
                  commaRegex.test(e.target.value)
                    ? setNewRecord({
                        ...newRecord,
                        price: e.target.value,
                      })
                    : null
                }
                InputProps={{
                  sx: {
                    color: "black",
                  },
                  startAdornment: (
                    <InputAdornment position="start">R$</InputAdornment>
                  ),
                }}
              />
              <TextField
                sx={{ m: 1 }}
                value={newRecord.lt}
                inputProps={{
                  step: 0.1,
                }}
                helperText="Quantos litros foram abastecidos"
                onChange={(e) =>
                  dotRegex.test(e.target.value) ||
                  commaRegex.test(e.target.value)
                    ? setNewRecord({
                        ...newRecord,
                        lt: e.target.value,
                      })
                    : null
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">L</InputAdornment>
                  ),
                }}
              />
            </FormGroup>
            <DatePicker
              label="Data"
              value={newRecord.date}
              onChange={(date) => {
                if (date) setNewRecord({ ...newRecord, date: date.format() });
              }}
              maxDate={dayjs()}
              renderInput={(params) => (
                <TextField
                  sx={{ m: 1 }}
                  label="Data"
                  helperText="Data do Abastecimento"
                  {...params}
                />
              )}
            />
          </FormGroup>
          {loadingSubmit.isError && (
            <Typography variant="caption" color="red">
              {loadingSubmit.msg}
            </Typography>
          )}
          <FormGroup>
            {loadingSubmit.value ? (
              <CircularProgress sx={{ margin: "0 auto" }} />
            ) : (
              <Button type="submit" variant="contained" color="success">
                Confirmar
              </Button>
            )}
            <br />
            <Button onClick={onClose}>Cancelar</Button>
          </FormGroup>
        </form>
      </Box>
    </Modal>
  );
};

export default CreateGasRecord;
