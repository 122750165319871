import {
  Grid,
  Button,
  TextField,
  FormGroup,
  Typography,
  CircularProgress,
  FormControl,
  InputLabel,
  OutlinedInput,
  Input,
  FormHelperText,
  InputAdornment,
  IconButton,
} from "@mui/material";
import {
  CSSProperties,
  FC,
  FormEventHandler,
  useEffect,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { login, loginViaToken } from "../api";
import Logo from "../assets/logo.png";
import colors from "../colors";
import { Buffer } from "buffer";
import { loadUser } from "../redux/user/slice";
import { getToken, setToken } from "../storage";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const logoStyle: CSSProperties = {
  padding: 10,
  backgroundColor: colors.primary,
  borderRadius: 5,
};

const inputStyle: CSSProperties = {
  margin: 8,
};

const initialValidation = {
  email: {
    value: false,
    msg: "",
  },
  password: {
    value: false,
    msg: "",
  },
};

const Login: FC = () => {
  const [creds, setCreds] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState({
    value: false,
    msg: "",
    isError: false,
  });

  const [passVis, setPassVis] = useState(false);

  const [validation, setValidation] = useState(initialValidation);

  const [logged, setLogged] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogin: FormEventHandler = (e) => {
    e.preventDefault();

    const emailRegex = RegExp(
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    );

    let auxValidation = {};

    if (!emailRegex.test(creds.email)) {
      auxValidation = {
        ...auxValidation,
        email: {
          value: true,
          msg: "email inválido",
        },
      };
    }

    if (creds.password.length === 0) {
      auxValidation = {
        ...auxValidation,
        password: {
          value: false,
          msg: "Senha não pode ser vazia.",
        },
      };
    }

    if (Object.keys(auxValidation).length > 0) {
      setValidation({
        ...validation,
        ...auxValidation,
      });
    } else {
      setLoading({
        ...loading,
        value: true,
      });

      login({
        ...creds,
        password: Buffer.from(creds.password).toString("base64"),
      })
        .then((res) => {
          console.log(res.data);

          const { token, user } = res.data;

          setToken(token);

          setLoading({
            msg: "Logado! Redirecionando...",
            isError: false,
            value: false,
          });

          setLogged(true);

          dispatch(loadUser(user));
        })
        .catch((err) => {
          setLoading({
            ...loading,
            value: false,
            isError: true,
            msg: err.response.data.msg
              ? err.response.data.msg
              : "Algo inesperado aconteceu, tente novamente mais tarde.",
          });
        });
    }
  };

  useEffect(() => {
    if (logged) {
      navigate("/dashboard");
    }
  }, [logged]);

  useEffect(() => {
    const token = getToken();
    if (token) {
      setLoading({
        isError: false,
        msg: "",
        value: true,
      });
      loginViaToken()
        .then((res) => {
          const { user } = res.data;

          if (user) {
            dispatch(loadUser(user));
            setLogged(true);
          }
        })
        .finally(() => {
          setLoading({
            isError: false,
            msg: "",
            value: false,
          });
        });
    }
  }, []);

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "100vh" }}
    >
      <Grid item xs={6}>
        <div style={{ height: "15vh" }}>
          <img style={logoStyle} src={Logo} alt="logo" />
        </div>
      </Grid>
      <Grid item xs={12}>
        {loading.msg && (
          <Typography color={loading.isError ? "red" : "green"}>
            {loading.msg}
          </Typography>
        )}
      </Grid>
      <Grid item xs={6}>
        <form
          onSubmit={handleLogin}
          style={{
            margin: "0 auto",
            width: "80%",
            padding: 16,
          }}
        >
          <FormGroup>
            <TextField
              style={inputStyle}
              variant="standard"
              label="Email"
              placeholder="Email"
              value={creds.email}
              onChange={(e) => setCreds({ ...creds, email: e.target.value })}
              error={validation.email.value}
              helperText={validation.email.msg}
              type="email"
              InputProps={{
                className: "login-input",
              }}
            />
            <FormControl style={inputStyle} variant="standard">
              <InputLabel sx={{ color: "#E5E5E5" }} htmlFor="pass-input">
                Senha
              </InputLabel>
              <Input
                sx={{
                  color: "#E5E5E5",
                  borderColor: "#E5E5E5",
                }}
                id="pass-input"
                type={passVis ? "text" : "password"}
                value={creds.password}
                onChange={(e) =>
                  setCreds({ ...creds, password: e.target.value })
                }
                error={validation.password.value}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      sx={{ color: "#E5E5E5" }}
                      onClick={() => setPassVis((prev) => !prev)}
                      edge="end"
                    >
                      {passVis ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {validation.password.msg && (
                <FormHelperText id="error-helper-text">
                  {validation.password.msg}
                </FormHelperText>
              )}
            </FormControl>
          </FormGroup>
          {loading.value ? (
            <CircularProgress />
          ) : (
            <Button
              type="submit"
              sx={{
                mt: 4,
                width: "100%",
              }}
              variant="outlined"
            >
              ENTRAR
            </Button>
          )}
          <Button
            sx={{
              mt: 2,
              width: "100%",
            }}
            variant="text"
          >
            <Link to="/signup">
              <Typography sx={{ color: "#E5E5E5" }} variant="caption">
                Registrar
              </Typography>
            </Link>
          </Button>
        </form>
      </Grid>
    </Grid>
  );
};

export default Login;
