import { createTheme } from "@mui/material";

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          "&.MuiButton-outlined": {
            borderColor: "#E5E5E5",
            color: "#E5E5E5",
          },
          "&.MuiButton-text": {
            color: "#000000",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiTextFieldFilledInput-root": {
            backgroundColor: "#14213D",
          },
          "& .MuiInputLabel-formControl.MuiInputLabel-root": {
            color: "#E5E5E5",
          },
          "& .MuiInputLabel-formControl.MuiInputLabel-root.MuiInputLabel-outlined":
            {
              color: "#14213D",
            },
          "& .MuiInput-underline": {
            borderBottom: "1px solid #E5E5E5",
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: "#E5E5E5",
          "&.MuiTypography-root.MuiTypography-body1.MuiListItemText-primary": {
            color: "#14213D",
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          "&.MuiButton-root.MuiButton-outlined": {
            borderColor: "#fca311",
            color: "#fca311",
            fontWeight: "bold",
            borderWidth: 2,
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          "&.MuiInputAdornment-root": {
            color: "#E5E5E5",
          },
        },
      },
    },
  },
});

export default theme;
