import { GasRecord } from "./types";

const GASRECORD = "GASRECORD";
const GASRECORDTOKEN = "GASRECORDTOKEN";

export const getStorage = () => localStorage.getItem(GASRECORD);

export const saveStorage = (storage: GasRecord[]) =>
  localStorage.setItem(GASRECORD, JSON.stringify(storage));

export const cleanStorage = () => localStorage.removeItem(GASRECORD);

export const setToken = (token: string) =>
  localStorage.setItem(GASRECORDTOKEN, token);

export const getToken = () => localStorage.getItem(GASRECORDTOKEN);
export const cleanToken = () => localStorage.removeItem(GASRECORDTOKEN);
