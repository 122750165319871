import axios from "axios";
import { getToken } from "./storage";
import { GasRecord, User } from "./types";
let defaultToken = getToken();

const api = axios.create({
  baseURL: "https://api.minhagasolina.com.br",
  // "http://localhost:8000/",
  headers: {
    "access-control-allow-origin": "*",
    authorization: defaultToken || "",
  },
});

interface GasRecordResponse {
  records: GasRecord[];
}

export const register = (user: User) => api.post("/user/signup", user);

export const login = (creds: { email: string; password: string }) =>
  api.post("/login", creds);

export const loginViaToken = () => api.get("login/token");

export const handleAddRecord = (record: GasRecord) =>
  api.post("/record/new", record);

export const ListGasRecord = (dateStart: string, dateEnd: string) =>
  api.post<GasRecordResponse>("/record/list", {
    period: {
      dateStart,
      dateEnd,
    },
  });

export const deleteGasRecord = (id: string) => api.delete(`/record/${id}`);
export const httpEditGasrecord = (record: GasRecord) =>
  api.put(`/record/edit/${record.id}`, record);

{
  let defaultToken = getToken();
  api.defaults.headers.common["authorization"] = defaultToken || "";
}
