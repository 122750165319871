import { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../redux/store";
import { DefaultProps } from "../types";

const Wrapper: FC<DefaultProps> = ({ children }) => {
  const userState = useSelector((state: RootState) => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (userState.user && !userState.user.logged) {
      navigate("/login");
    }
  }, [userState.user]);

  return (
    <div
      style={{
        padding: 4,
      }}
    >
      {children}
    </div>
  );
};

export default Wrapper;
