import {
  Button,
  CircularProgress,
  FormGroup,
  InputAdornment,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import colors from "../colors";
import { v4 as uuidv4 } from "uuid";
import { GasRecord } from "../types";
import { Box, SxProps } from "@mui/system";
import { DatePicker } from "@mui/x-date-pickers";
import { FC, FormEventHandler, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { addRecord, editRecord } from "../redux/gas-records/slice";
import { httpEditGasrecord } from "../api";

type GasRecordModal = {
  open: boolean;
  record: GasRecord;
  onClose: () => void;
};

const style: SxProps = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: colors.contrast,
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

const typographyTitle: SxProps = {
  color: "#14213D",
  mb: 2,
};

const dotRegex = /^\d+(\.\d{0,2})?$/;
const commaRegex = /^\d+(,\d{0,2})?$/;

type editedType = {
  id: string;
  date: string;
  km: number;
  lt: string;
  price: string;
};

const EditGasRecord: FC<GasRecordModal> = (props) => {
  const { open, onClose, record } = props;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const [editedRecord, setEditRecord] = useState<editedType>({
    id: "null",
    date: dayjs().format(),
    km: 0,
    lt: "",
    price: "",
  });

  useEffect(() => {
    if (record) {
      setEditRecord({
        id: record.id,
        date: record.date,
        km: record.km,
        lt: record.lt.toString(),
        price: record.price.toString(),
      });
    }
  }, [record]);

  const handleSubmit: FormEventHandler = (e) => {
    e.preventDefault();
    const { date, km, lt, price } = editedRecord;

    let numLT = Number(lt.replace(",", "."));
    let numPRICE = Number(price.replace(",", "."));

    if (!isNaN(numPRICE) && !isNaN(numLT) && km > 0 && dayjs(date).isValid()) {
      const aux: GasRecord = {
        id: editedRecord.id,
        date: editedRecord.date,
        km: editedRecord.km,
        lt: numLT,
        price: numPRICE,
      };
      setLoading(true);
      httpEditGasrecord(aux).then((res) => {
        dispatch(editRecord(aux));
        setLoading(false);
        onClose();
      });
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <Typography sx={typographyTitle} align="center" variant="h5">
          Editar Abastecmento
        </Typography>
        <form onSubmit={handleSubmit} style={{ margin: "0 auto" }}>
          <FormGroup>
            <TextField
              sx={{ m: 1 }}
              value={editedRecord.km}
              type="number"
              helperText="Valor de Km no Hodômetro"
              onChange={(value) =>
                setEditRecord({
                  ...editedRecord,
                  km: Number(value.target.value),
                })
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">Km</InputAdornment>
                ),
              }}
            />
            <FormGroup>
              <TextField
                sx={{ m: 1 }}
                value={editedRecord.price}
                type="number"
                helperText="Quanto foi pago no Posto"
                onChange={(e) =>
                  dotRegex.test(e.target.value) ||
                  commaRegex.test(e.target.value)
                    ? setEditRecord({
                        ...editedRecord,
                        price: e.target.value,
                      })
                    : null
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">R$</InputAdornment>
                  ),
                }}
              />
              <TextField
                sx={{ m: 1 }}
                value={editedRecord.lt}
                inputProps={{
                  step: 0.1,
                }}
                helperText="Quantos litros foram abastecidos"
                onChange={(e) =>
                  dotRegex.test(e.target.value) ||
                  commaRegex.test(e.target.value)
                    ? setEditRecord({
                        ...editedRecord,
                        lt: e.target.value,
                      })
                    : null
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">L</InputAdornment>
                  ),
                }}
              />
            </FormGroup>
            <DatePicker
              label="Data"
              value={editedRecord.date}
              onChange={(date) => {
                if (date) setEditRecord({ ...editedRecord, date });
              }}
              renderInput={(params) => (
                <TextField
                  sx={{ m: 1 }}
                  helperText="Data do Abastecimento"
                  {...params}
                />
              )}
            />
          </FormGroup>
          <FormGroup>
            {loading ? (
              <CircularProgress />
            ) : (
              <Button type="submit" variant="contained" color="success">
                Editar
              </Button>
            )}
            <br />
            <Button onClick={onClose}>Cancelar</Button>
          </FormGroup>
        </form>
      </Box>
    </Modal>
  );
};

export default EditGasRecord;
