import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "../../types";

export interface UserState {
  user: User;
}

const initialState: UserState = {
  user: {
    name: "",
    email: "",
    password: "",
    logged: false,
  },
};

export const UserSlice = createSlice({
  name: "User",
  initialState,
  reducers: {
    loadUser(state, action: PayloadAction<User>) {
      state.user = {
        ...action.payload,
        logged: true,
      };
    },
    delUser(state) {
      state.user = initialState.user;
    },
    editUser(state, action: PayloadAction<Partial<User>>) {
      state.user = {
        ...state.user,
        ...action.payload,
      };
    },
  },
});

export const { loadUser, delUser, editUser } = UserSlice.actions;
export default UserSlice.reducer;
