import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { GasRecord } from "../../types";

export interface GasRecordState {
  value: GasRecord[];
}

const initialState: GasRecordState = {
  value: [],
};

export const GasRecordSlice = createSlice({
  name: "GasRecord",
  initialState,
  reducers: {
    addRecord: (state, action: PayloadAction<GasRecord>) => {
      state.value.push(action.payload);
      state.value = state.value.sort((a, b) => {
        if (dayjs(a.date).isAfter(dayjs(b.date))) {
          return -1;
        } else {
          return 1;
        }
      });
    },
    editRecord: (state, action: PayloadAction<GasRecord>) => {
      state.value = state.value.map((r) =>
        r.id === action.payload.id ? action.payload : r
      );
    },
    delRecord: (state, action: PayloadAction<GasRecord>) => {
      state.value = state.value.filter((r) => r.id !== action.payload.id);
    },
    loadRecords: (state, action: PayloadAction<GasRecord[]>) => {
      state.value = action.payload;
    },
    cleanRecords: (state) => {
      state.value = [];
    },
  },
});

export const { addRecord, editRecord, delRecord, loadRecords, cleanRecords } =
  GasRecordSlice.actions;
export default GasRecordSlice.reducer;
