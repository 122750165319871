import {
  Avatar,
  Collapse,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { FC, MouseEventHandler, useState } from "react";
import { GasRecord } from "../types";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import colors from "../colors";
import { Delete, Edit } from "@mui/icons-material";
import { Box } from "@mui/system";
import EditGasRecord from "./EditGasRecord";
import { useDispatch } from "react-redux";
import { delRecord } from "../redux/gas-records/slice";
import { deleteGasRecord } from "../api";

export type GasRecordProps = {
  record: GasRecord;
  onClick: () => void;
};

const GasRecordItem: FC<GasRecordProps> = ({ record, onClick }) => {
  const [showButtons, setShowButtons] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  const dispatch = useDispatch();

  const handleDeleteGasRecord: MouseEventHandler = (e) => {
    e.preventDefault();

    if (window.confirm("Apagar registro?")) {
      deleteGasRecord(record.id).then((res) => {
        dispatch(delRecord(record));
      });
    }
  };

  return (
    <Box>
      <EditGasRecord
        open={openEdit}
        record={record}
        onClose={() => setOpenEdit(!openEdit)}
      />
      <ListItem>
        <ListItemButton
          onClick={() => {
            setShowButtons(!showButtons);
          }}
          alignItems="flex-start"
        >
          <ListItemAvatar>
            <Avatar>
              <LocalGasStationIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            disableTypography
            primary={
              <Typography
                sx={{ color: colors.info }}
                variant="body1"
                fontWeight="bold"
              >
                {`R$ ${record.price} - ${record.lt} L`}
              </Typography>
            }
            secondary={
              <>
                <p style={{ margin: 0 }}>
                  {`Preço do litro R$ ${(record.price / record.lt).toFixed(2)}`}
                </p>
                <p style={{ margin: 0 }}>{`${record.km} km`}</p>
              </>
            }
          />
          <ListItemText
            sx={{ textAlign: "end" }}
            secondary={
              dayjs(record.date).isSame(dayjs(), "day")
                ? "Hoje"
                : dayjs(record.date).format("DD/MM/YYYY")
            }
          />
        </ListItemButton>
      </ListItem>
      {
        <Collapse in={showButtons}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "right",
              mr: 2,
            }}
          >
            <IconButton onClick={() => setOpenEdit(true)}>
              <Edit />
            </IconButton>
            <IconButton onClick={handleDeleteGasRecord}>
              <Delete />
            </IconButton>
          </Box>
        </Collapse>
      }
    </Box>
  );
};

export default GasRecordItem;
