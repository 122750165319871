import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  FormGroup,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { AxiosError } from "axios";
import {
  ChangeEventHandler,
  CSSProperties,
  FC,
  FormEventHandler,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { register } from "../api";
import Logo from "../assets/logo.png";
import { loadUser } from "../redux/user/slice";
import colors from "../colors";
import { User } from "../types";
import { Box, SxProps } from "@mui/system";
import { Buffer } from "buffer";

const logoStyle: CSSProperties = {
  padding: 10,
  backgroundColor: colors.primary,
  borderRadius: 5,
};

const inputStyle: CSSProperties = {
  margin: 2,
};

const VisibilityStyle: CSSProperties = {
  color: "#e5e5e5",
};

const modalStyle: SxProps = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: colors.main,
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

const initialValidation = {
  email: {
    value: false,
    msg: "",
  },
  name: {
    value: false,
    msg: "",
  },
  password: {
    value: false,
    msg: "",
  },
};

const SignUp: FC = (props) => {
  const [userCreated, setUserCreated] = useState(false);
  const [loading, setLoading] = useState({
    value: false,
    msg: "",
    isError: false,
  });
  const [confPass, setConfPass] = useState("");
  const [visPass, setVisPass] = useState({
    pass: false,
    confPass: false,
  });
  const [newUser, setNewUser] = useState<User>({
    email: "",
    logged: false,
    name: "",
    password: "",
  });

  const [validation, setValidation] = useState(initialValidation);

  const dispatch = useDispatch();

  const handleChange =
    (
      field: string
    ): ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> =>
    (e) => {
      setNewUser({
        ...newUser,
        [field]: e.target.value,
      });
    };

  const handleConfPass: ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (e) => setConfPass(e.target.value);

  const visibilityIcon = (option: boolean) =>
    option ? (
      <Visibility style={VisibilityStyle} />
    ) : (
      <VisibilityOff style={VisibilityStyle} />
    );

  const handleSignUp: FormEventHandler = (e) => {
    e.preventDefault();
    setValidation(initialValidation);

    const emailRegex = RegExp(
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    );

    let auxValidation = {};

    if (!newUser.name) {
      auxValidation = {
        ...auxValidation,
        name: {
          value: true,
          msg: "Nome não pode ser vazio!!",
        },
      };
    }

    if (!emailRegex.test(newUser.email)) {
      auxValidation = {
        ...auxValidation,
        email: {
          value: true,
          msg: "Email inválido!",
        },
      };
    }

    if (newUser.password !== confPass || !newUser.password || !confPass) {
      auxValidation = {
        ...auxValidation,
        password: {
          value: true,
          msg: "Senhas não conferem!!",
        },
      };
    }

    if (Object.keys(auxValidation).length > 0) {
      setValidation({
        ...validation,
        ...auxValidation,
      });
    } else {
      setLoading({
        ...loading,
        value: true,
      });

      setValidation(initialValidation);

      register({
        ...newUser,
        password: Buffer.from(newUser.password).toString("base64"),
      })
        .then((res) => {
          if (res.data.user) {
            dispatch(loadUser(res.data.user));
            setUserCreated(true);
            setLoading({
              ...loading,
              msg: "",
              value: false,
            });
          }
        })
        .catch((err) => {
          let message = "Algo de errado aconteceu, tente novamente mais tarde";
          if (err instanceof AxiosError) {
            if (err.response?.data) {
              message = err.response?.data.msg;
            }
          }
          setLoading({
            isError: true,
            msg: message,
            value: false,
          });
        });
    }
  };

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "100vh" }}
    >
      <Grid item xs={6}>
        <div style={{ height: "15vh" }}>
          <img style={logoStyle} src={Logo} alt="logo" />
        </div>
        {loading.isError && (
          <Typography variant="body2" sx={{ color: "red" }}>
            {loading.msg}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12}>
        <Modal open={userCreated}>
          <Box sx={modalStyle}>
            <Typography
              align="center"
              fontWeight="bold"
              component="h1"
              sx={{
                mb: 5,
                color: colors.primary,
              }}
            >
              🎉🎉 Conta criada! 🎉🎉
            </Typography>
            <Typography
              variant="body1"
              sx={{
                mb: 1,
                color: colors.primary,
              }}
              align="center"
            >
              Oba!! Que ótimo você estar com a gente!
            </Typography>
            <Typography
              variant="body1"
              sx={{
                mb: 4,
                color: colors.primary,
              }}
              align="center"
            >
              Agora você só precisa acessar seu painel e adicionar seus
              registros de abastecimento!!
            </Typography>
            <Button sx={{ width: "100%" }} variant="outlined">
              <Link
                style={{ textDecoration: "none", color: "inherit" }}
                to="/dashboard"
              >
                Ir para meu painel {">>"}
              </Link>
            </Button>
          </Box>
        </Modal>
      </Grid>
      <Grid item xs={6}>
        <form
          onSubmit={handleSignUp}
          style={{
            margin: "0 auto",
            width: "80%",
            padding: 16,
          }}
        >
          <FormGroup>
            <TextField
              style={inputStyle}
              variant="standard"
              value={newUser.name}
              label="Nome"
              onChange={handleChange("name")}
              InputProps={{
                className: "login-input",
              }}
              error={validation.name.value}
              helperText={validation.name.msg}
            />
            <TextField
              style={inputStyle}
              variant="standard"
              value={newUser.email}
              label="Email"
              onChange={handleChange("email")}
              error={validation.email.value}
              helperText={validation.email.msg}
              InputProps={{
                className: "login-input",
              }}
            />
            <TextField
              className="login-input"
              style={inputStyle}
              variant="standard"
              value={newUser.password}
              label="Senha"
              type={visPass.pass ? "text" : "password"}
              onChange={handleChange("password")}
              error={validation.password.value}
              helperText={validation.password.msg}
              InputProps={{
                className: "login-input",
                endAdornment: (
                  <IconButton
                    onClick={() =>
                      setVisPass({ ...visPass, pass: !visPass.pass })
                    }
                  >
                    {visibilityIcon(visPass.pass)}
                  </IconButton>
                ),
              }}
            />
            <TextField
              style={inputStyle}
              variant="standard"
              value={confPass}
              type={visPass.confPass ? "text" : "password"}
              onChange={handleConfPass}
              label="Confirme a Senha"
              error={validation.password.value}
              helperText={validation.password.msg}
              InputProps={{
                className: "login-input",
                endAdornment: (
                  <IconButton
                    onClick={() =>
                      setVisPass({ ...visPass, confPass: !visPass.confPass })
                    }
                  >
                    {visibilityIcon(visPass.confPass)}
                  </IconButton>
                ),
              }}
            />
          </FormGroup>
          {loading.value ? (
            <div style={{ width: "100%" }}>
              <CircularProgress />
            </div>
          ) : (
            <Button
              type="submit"
              sx={{
                mt: 4,
                width: "100%",
              }}
              variant="outlined"
            >
              Criar{" "}
            </Button>
          )}
          <Button sx={{ mt: 2 }} variant="text">
            <Link to="/login">
              <Typography sx={{ color: "#E5E5E5" }} variant="caption">
                voltar
              </Typography>
            </Link>
          </Button>
        </form>
      </Grid>
    </Grid>
  );
};

export default SignUp;
