import { CSSProperties, FC } from "react";
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  List,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Box } from "@mui/system";
import colors from "../colors";
import CreateGasRecord from "../components/CreateGasRecord";
import { GasRecord } from "../types";

import { saveStorage } from "../storage";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../redux/store";

import Logo from "../assets/logo.png";
import GasRecordItem from "../components/GasRecordItem";
import { AccountCircle } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { ListGasRecord } from "../api";
import { loadRecords } from "../redux/gas-records/slice";

const outlinedButton: CSSProperties = {
  borderColor: colors.contrast,
  color: colors.contrast,
};

const Dashboard: FC = () => {
  const [gasList, setGasList] = useState<GasRecord[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [stat, setStat] = useState({
    avgPrice: 0,
    avgAutonomy: 0,
    lastPrice: 0,
    totalKM: 0,
  });
  const [loadingRecords, setLoadingRecords] = useState(false);
  const GasRecordList = useSelector((state: RootState) => state.gasRecord);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (gasList.length > 0) {
      let totalPrice = 0;
      let totalLt = 0;
      let totalKM = 0;
      let avgPrice = 0;
      let diffs: number[] = [];

      for (let i = 0; i < gasList.length; i++) {
        const record = gasList[i];

        if (i < gasList.length - 1) {
          const lastRecord = gasList[i + 1];
          let diff = (record.km - lastRecord.km) / record.lt;
          diffs.push(diff);
        }

        totalPrice += record.price;
        totalLt += record.lt;
        totalKM += record.km;
      }
      avgPrice = totalPrice / totalLt;

      setStat({
        avgPrice,
        totalKM,
        lastPrice: gasList[0].price / gasList[0].lt,
        avgAutonomy: diffs.reduce((a, b) => a + b, 0) / diffs.length,
      });
    } else {
      setStat({
        avgPrice: 0,
        totalKM: 0,
        lastPrice: 0,
        avgAutonomy: 0,
      });
    }
  }, [gasList]);

  useEffect(() => {
    if (GasRecordList.value.length <= 0) {
      let d1 = dayjs().subtract(6, "month").format();
      let d2 = dayjs().format();

      setLoadingRecords(true);

      ListGasRecord(d1, d2)
        .then((res) => {
          const { records } = res.data;

          if (records) {
            let descSortedArray = records.sort((a, b) => {
              if (dayjs(a.date).isAfter(dayjs(b.date))) {
                return -1;
              } else {
                return 1;
              }
            });

            setGasList(descSortedArray);
            saveStorage(descSortedArray);
            dispatch(loadRecords(descSortedArray));
          }
        })
        .finally(() => {
          setLoadingRecords(false);
        });
    } else {
      setGasList(GasRecordList.value);
    }
  }, [GasRecordList.value]);

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} sx={{ mt: 1.5 }} />
      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <img
            style={{
              height: "100%",
              padding: 4,
              margin: 4,
              verticalAlign: "middle",
              borderRadius: 5,
              backgroundColor: colors.contrast,
            }}
            src={Logo}
            alt="logo"
          />

          <IconButton sx={{ float: "right" }}>
            <Link to="/account">
              <AccountCircle sx={{ color: colors.primary }} />
            </Link>
          </IconButton>
        </Box>
      </Grid>
      <Grid item justifyContent="center" xs={12}>
        <Box
          sx={{
            height: "30vh",
            backgroundColor: colors.main,
            mt: 2,
            marginLeft: 0.5,
            marginRight: 0.5,
            padding: 5,
          }}
        >
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Typography align="left" variant="body2">
                Autonomia
              </Typography>
              <Typography align="left" variant="h2" fontWeight="bold">
                {stat.avgAutonomy > 0 ? stat.avgAutonomy.toFixed(2) : "-"}{" "}
                <span style={{ fontSize: 20 }}> Km/L</span>
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ mt: 4 }} />
            <Grid item xs={6}>
              <Typography align="left" variant="body2">
                Preço Médio
              </Typography>
              <Typography align="left" variant="h4">
                <span style={{ fontSize: 20 }}>R$ </span>
                {stat.avgPrice > 0 ? stat.avgPrice.toFixed(2) : "-"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography align="left" variant="body2">
                Último preço
              </Typography>
              <Typography align="left" variant="h4">
                <span style={{ fontSize: 20 }}>R$ </span>
                {stat.lastPrice > 0 ? stat.lastPrice.toFixed(2) : "-"}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Button
          style={outlinedButton}
          onClick={() => setOpenModal(true)}
          variant="outlined"
          sx={{
            ml: 1,
            float: "left",
          }}
        >
          ⛽ Estou Abastecendo
        </Button>
        <CreateGasRecord open={openModal} onClose={() => setOpenModal(false)} />
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{
            borderRadius: 1,
            minHeight: "70vh",
            margin: "0 auto",
            mt: 1,
            backgroundColor: colors.contrast,
          }}
        >
          {loadingRecords ? (
            <CircularProgress />
          ) : gasList.length > 0 ? (
            <List>
              {gasList.map((record, i, arr) => {
                return (
                  <div key={i}>
                    <GasRecordItem record={record} onClick={() => {}} />
                  </div>
                );
              })}
            </List>
          ) : (
            <Typography
              sx={{
                color: colors.info,
                mt: 1,
                pt: 2,
              }}
              variant="body1"
              fontWeight="bold"
            >
              Muito vazio aqui!
              <br />
              Que tal preencher com os ultimos abastecimentos?
            </Typography>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default Dashboard;
