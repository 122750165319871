import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Wrapper from "./components/Wrapper";
import Account from "./pages/Account";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import SignUp from "./pages/signup";

const AppRouter: FC = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Wrapper>
            <Dashboard />
          </Wrapper>
        }
      />
      <Route path="login" element={<Login />} />
      <Route path="signup" element={<SignUp />} />
      <Route
        path="dashboard"
        element={
          <Wrapper>
            <Dashboard />
          </Wrapper>
        }
      />
      <Route
        path="account"
        element={
          <Wrapper>
            <Account />
          </Wrapper>
        }
      />
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
};

export default AppRouter;
