import "./App.css";
import AppRouter from "./AppRouter";

function App() {
  return (
    <div
      className="App"
      style={{ maxWidth: 800, minWidth: 340, margin: "0 auto" }}
    >
      <AppRouter />
    </div>
  );
}

export default App;

/**
 *  <Box sx={{ m: 2 }}>
            <form style={{ width: "100%" }}>
              <FormGroup sx={{ width: 350, margin: "0 auto" }}>
                <TextField
                  sx={{ m: 1 }}
                  label="Km"
                  helperText="Quantos Km marcam no hodômetro"
                  placeholder="0000Km"
                  value={newRecord.km}
                />
                <TextField
                  sx={{ m: 1 }}
                  label="Litros"
                  placeholder="00L"
                  helperText="Escreva quantos litros foram abastecidos"
                  value={newRecord.Lt}
                />
              </FormGroup>
            </form>
          </Box>
 */
