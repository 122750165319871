import { configureStore } from "@reduxjs/toolkit";
import GasRecordReducer from "./gas-records/slice";
import UserReducer from "./user/slice";
import logger from "redux-logger";
import { useDispatch } from "react-redux";

export const store = configureStore({
  reducer: {
    gasRecord: GasRecordReducer,
    user: UserReducer,
  },
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
